const randNumber = Math.random()
const date = new Date()
class TokenStorage {
  // На момент разработки будем хранить токен в local storage браузера
  constructor() {
      this.storage = window.localStorage;
      this.storageKey = "auth-token";
  }

  getToken() {

      return `${randNumber}-${date.getTime()}`;
  }

  setToken(token) {
      this.storage.setItem(this.storageKey, token);
  }

  removeToken() {
      this.storage.removeItem(this.storageKey);
  }
}

export default new TokenStorage();
